/* file gets modified via the deploy system to customize information for a particular build */

const alertLiveDefaults = {
    app_name: 'Alert.Live',
    defaultBounds: {
        north: 63.27232558311539,
        south: 5.837794296759071,
        east: -52.92648164175864,
        west: -146.57385537051704,
        zoom: 2.7141759352212462,
        lng: -99.7501685061385,
        lat: 40.29677848980003,
    },
}

const appDefaults = {
    RTE: { default: {} },
    'alert.live': {
        reno: {
            ...alertLiveDefaults,
            defaultBounds: {
                north: 40.30138016597601,
                south: 38.91559312648391,
                east: -118.54262733773359,
                west: -121.65229671992074,
                zoom: 7.445678944034934,
                lng: -120.09746202882741,
                lat: 39.61195399062214,
            },
        },
        hellenes: {
            ...alertLiveDefaults,
            defaultBounds: {
                north: 42.775891946338135,
                south: 34.295992383728944,
                east: 29.86889368624648,
                west: 16.571140587442216,
                zoom: 5.530231974619533,
                lng: 23.22001713684415,
                lat: 38.66128629751066,
            },
        },
        santafe: {
            ...alertLiveDefaults,
            defaultBounds: {
                north: 35.73282199283091,
                south: 35.5631952665008,
                east: -105.87895275298673,
                west: -106.13447234922442,
                zoom: 11.2318367225468,
                lng: -106.00671255110586,
                lat: 35.64805365069658,
            },
        },
        dafault: {
            ...alertLiveDefaults,
        },
    },
}

// const default_root_app = 'alert.live'
const default_root_app = 'rte'

// const default_sub_app = 'hellenes'
const default_sub_app = null

let config = {
    build_number: '11480',
    build_branch: 'production',
    build_time: 1743202891000,
    root_app: 'RTE',
    sub_app: '',
    default_sub_app,
    default_root_app,
    build_root: '/',
    app_name: 'Realtime.Earth',
}

let rootApp = config.root_app || config.default_root_app
let subApp = config.sub_app || config.default_sub_app

if (appDefaults[rootApp]) {
    config = {
        ...config,
        ...appDefaults[rootApp][subApp],
        root_app: rootApp,
        sub_app: subApp,
    }
}

console.log('Root App: ', rootApp)
console.log('config: ', config)

export { config }
